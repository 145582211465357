app.controller('HeaderCtrl', function($scope, $rootScope, $timeout, AppFactory) {

  $scope.syncIng           = false;
  $scope.getTitle          = AppFactory.getTitle;
  $scope.getTemplateHeader = AppFactory.getTemplateHeader;
  $scope.goBack            = AppFactory.goBack;
  $scope.exit              = AppFactory.exit;
  $scope.showSiteBar       = AppFactory.showSiteBar;
  $scope.goEditBack        = AppFactory.goBack;

  $scope.refreshActivityReportsOverview = () => $rootScope.$broadcast('refresh-ar-overview');

  var syncHandler = $rootScope.$on('sync', function(e, data) {
    $timeout(function() {
      $scope.syncIng = data.state === 'start';
    });
  });

  $scope.$on('$destroy', function () {
    syncHandler();
    $scope.getTitle          = null;
    $scope.getTemplateHeader = null;
    $scope.goBack            = null;
    $scope.exit              = null;
    $scope.showSiteBar       = null;
    $scope.goEditBack        = null;
  });
});
