'use strict';

app.controller('PrivacySummaryCtrl', function (
		$rootScope,
		$scope,
		$location,
		$translate,
		AppFactory,
		API,
		AuthService,
		ErrFactory,
		LoaderFactory
	) {

	if (window.cordova && window.navigator.splashscreen) window.navigator.splashscreen.hide();

	$scope.submitForm = function (userForm) {
		if (!AppFactory.isNetwork()) return AppFactory.networkProblem();
		if (!userForm.$invalid) {
			LoaderFactory.show();

			Promise.resolve(API.Users.acceptPrivacyPolicy().$promise)
			.then(() => AuthService.getAuthorizedUser().update()
				.then(() => {
					$rootScope.$broadcast('refreshProjects');
					$location.path('/projects');
				})
				.catch((err) => { console.error(err); })
			)
			.catch((err) => {
				if (err instanceof ErrFactory) err.notify();
				else console.error(err);
			})
			.then(() => LoaderFactory.hide());
		} else {
			let names = userForm.$error.required.map((el) => el.$name);
			$translate(names).then((list) => {
				let message = names.map((n) => list[n]).join('\n');
				(new ErrFactory.RegisterError(message)).notify();
			});
		}
	};

	$scope.logout = function (e) {
		e.stopPropagation();
		LoaderFactory.show();
		return AuthService.logout()
			.then(() =>Promise.resolve())
			["catch"]((err) => err.notify())
			.then(() => LoaderFactory.hide());
	};

});
