window.app.controller('GlobalCtrl', ['$scope', '$timeout', '$rootScope', '$location', 'AppFactory', 'LoaderFactory', 'SidebarFactory', 'BackButtonService',
  'ReducedService', 'INDEX_URL', 'Notification', '$translate', '$log',
  function($scope, $timeout, $rootScope, $location, AppFactory, LoaderFactory, SidebarFactory, BackButtonService,
    ReducedService, INDEX_URL, Notification, $translate, $log) {
  var init, togglePreloader, toggleProgress;
  var toggleDebugScreenClicks = 0;
  $scope.debugScreenMode = false;

  $scope.styleModelOverlay = '';
  $scope.wrapperClasses = '';
  $scope.flagInterruption = false;
  $scope.preloader = {
    description: '',
    isActive: false,
    style: 'white',
    message: ''
  };
  $scope.progress = {
    visible: false,
    value: 0,
    label: '',
    message: '',
    radius: 91.6
  };

  $scope.progress.circumference = $scope.progress.radius * 2 * Math.PI;
  $scope.progress.array = $scope.progress.circumference + ' ' + $scope.progress.circumference;

  // $scope.visibleSyncButton = !ReducedService.sync_project;
  $scope.showCancelText = false;
  init = function() {
    if (window.device && window.device.platform === 'iOS') {
      document.body.className += ' ios';
    }

    document.addEventListener('backbutton', function(e) {
      if ( BackButtonService.apply() === false ) {
        if ($location.path() === '/blocker') e.preventDefault();
        else if ($location.path() !== INDEX_URL && $location.path() !== '/sign-in') {
          $timeout(function() {
            $location.path(INDEX_URL);
          });
        } else {
          AppFactory.goBack();
        }
      } else {
        e.preventDefault();
      }
    }, false);
  };

  $scope.Interruption = function() {
    $scope.flagInterruption = true;
    return LoaderFactory.setInterruption();
  };
  $scope.syncProjects = function() {
    return AppFactory.checkNewVersion()
    .then(() => $rootScope.$broadcast('project.sync'))
    .catch((err) => {
      if (err && err.store_url) {
        $location.path('/blocker');
        $scope.$apply();
      }
    })
  };
  $scope.refrashStore = function () {
    return $scope.$broadcast('store.refrash');
  };
  $scope.hideSidebar = function(e) {
    SidebarFactory.hide();
    return false;
  };
  togglePreloader = function() {
    return $timeout(function() {
      $scope.preloader.isActive = LoaderFactory.isActive();
      return $scope.preloader.style = LoaderFactory.getStyle();
    });
  };
  toggleProgress = function() {
    return $timeout(function() {
      return $scope.progress.visible = LoaderFactory.isVisibleProgress();
    });
  };
  SidebarFactory.on('changed:state', function() {
    var style;
    style = SidebarFactory.isActive() ? 'active' : '';
    return $timeout(function() {
      return $scope.styleModelOverlay = style;
    });
  });
  LoaderFactory.on('updated:progressmessage', function() {
    return $timeout(function() {
      return $scope.progress.message = LoaderFactory.getProgressMessage();
    });
  });
  LoaderFactory.on('updated:preloadermessage', function() {
    return $timeout(function() {
      return $scope.preloader.message = LoaderFactory.getPreloaderMessage();
    });
  });
  LoaderFactory.on('updated:Interruption', function() {
    return $timeout(function() {
      return $scope.flagInterruption = LoaderFactory.getInterruption();
    });
  });
  LoaderFactory.on('show', togglePreloader);
  LoaderFactory.on('hide', togglePreloader);
  LoaderFactory.on('showPregress', toggleProgress);
  LoaderFactory.on('hidePregress', toggleProgress);
  LoaderFactory.on('updated:progressvalue', function() {
    return $timeout(function() {
      $scope.progress.offset = $scope.progress.circumference - LoaderFactory.getProgressValue() / 100 * $scope.progress.circumference;
      return $scope.progress.value = LoaderFactory.getProgressValue();
    });
  });
  LoaderFactory.on('updated:description', function() {
    return $timeout(function() {
      return $scope.preloader.description = LoaderFactory.getDescription();
    });
  });
  LoaderFactory.on('handleCancelText', function() {
    return $timeout(function() {
      return $scope.showCancelText = LoaderFactory.handleCancelButtonText();
    });
  });
  LoaderFactory.on('updated:progresslabel', function() {
    return $timeout(function() {
      return $scope.progress.label = LoaderFactory.getProgressLabel();
    });
  });
  var $routeChangeSuccessHandler = $scope.$on('$routeChangeSuccess', function(e, nextRoute) {
    if (AppFactory.getTemplateHeader()) {
      return $scope.wrapperClasses = 'has-navbar-top';
    } else {
      return $scope.wrapperClasses = '';
    }
  });

  $scope.changeLocation = function(to) {
    return $location.path(to);
  };

  $rootScope.toggleDebugScreen = function(event) {
    event = event || window.event;
    var target = event.target || event.srcElement;
    event.preventDefault();
    event.stopPropagation();

    var TOTAL_CLICKS = 10;
    var message, duration, position;

    duration = 'short';
    position = 'top';

    toggleDebugScreenClicks++;

    if (toggleDebugScreenClicks >= TOTAL_CLICKS) {
      toggleDebugScreenClicks = 0;
      $scope.debugScreenMode = !$scope.debugScreenMode;
      message = $scope.debugScreenMode ? 'debugScreenActivated' : 'debugScreenDeactivated';

      return $translate([message]).then(function(t) {
        return Notification.message(t[message], duration, position);
      })

    } else {
      message = $scope.debugScreenMode ? 'deactivateDebugScreen' : 'activateDebugScreen';
      var plural = (TOTAL_CLICKS - toggleDebugScreenClicks === 1) ? '' : 's';

      return $translate([message], {
        number: TOTAL_CLICKS - toggleDebugScreenClicks,
        s: plural
      }).then(function(t) {
        return Notification.message(t[message], duration, position);
      })
    }
  };

  /**
  * Overwrites console.error and $log.error to write errors to localStorage
  * to use this data in debug screen.
  */
  function processErrors(a?) {
    var output: any = {
      severity: 'ERROR',
      timeStamp: Math.floor((new Date).valueOf() / 1000),
      additional_data: [{}]
    };
    var consoleErrors;

    var args = arguments[0];

    for (var i = 0; i < args.length; i++) {
      var arg = args[i];

      if ( arg instanceof Error || Object.prototype.toString.call(arg) === '[object Object]' ) {
        if (!output.additional_data[0].name) output.additional_data[0].name = arg.name ? arg.name : (arg.error && arg.error.name) ? arg.error.name : '';
        if (!output.message) output.message = arg.message ? arg.message : (arg.error && arg.error.message) ? arg.error.message : '';
        if (!output.trace) output.trace = arg.stack ? arg.stack : (arg.error && arg.error.stack) ? arg.error.stack : '';

      } else {
        if (!output.message) output.message = arg;
      }
    }

    if (output.message) {
      if (typeof(output.message) !== 'string') output.message = output.message.toString();
      consoleErrors = window.localStorage.getItem('consoleErrors');

      if (consoleErrors) {
        consoleErrors = JSON.parse(consoleErrors);

        consoleErrors.push(output);

        if (consoleErrors.length > 50) consoleErrors.splice(0, consoleErrors.length - 50);

        window.localStorage.setItem('consoleErrors', JSON.stringify(consoleErrors));

      } else {
        window.localStorage.setItem('consoleErrors', JSON.stringify([output]));
      }
    }
  }

  function handleConsoleErrors() {
    processErrors(arguments);
    return console.oldError.apply(undefined, arguments);
  }

  function handleConsoleExceptions() {
    processErrors(arguments);
    return console.oldException.apply(undefined, arguments);
  }

  function handleAngularErrors() {
    processErrors(arguments);
    return $log.oldError.apply(undefined, arguments);
  }

  (function overWriteConsole() {
    console.oldError = console.error;
    console.error = handleConsoleErrors;

    console.oldException = console.exception;
    console.exception = handleConsoleExceptions;

    $log.oldError = $log.error;
    $log.error = handleAngularErrors;
  })();
  // End of console.error overwrites.

  function handlePromiseErrors(event) {

    return processErrors([{
      message: `Unhandled promise rejection: ${event.reason && event.reason.message || ''}`
    }]);
  }

  function handleWindowErrors(event) {
    return processErrors([event]);
  }

  window.addEventListener('unhandledrejection', handlePromiseErrors);
  window.addEventListener('error', handleWindowErrors);

  $scope.shotMessage = null;
  $scope.shotMessageShow = false;
  let clearId = null;

  const shotMeassageEvent = $scope.$on('shot_message',(e,{message}) => {
    $scope.shotMessage = message;
    $scope.shotMessageShow = true;
    if (clearId) {
      clearTimeout(clearId);
      clearId = null;
    }
    clearId = setTimeout(() => {
      $scope.shotMessageShow = false;
      clearId = null;
      $scope.$apply();
    }, 2000)
  });

  $scope.$on('$destroy', function () {
    $routeChangeSuccessHandler();
    shotMeassageEvent();
    window.removeEventListener('unhandledrejection', handlePromiseErrors);
    window.removeEventListener('error', handleWindowErrors);
  });

  return document.addEventListener('deviceready', init, false);
}]);
