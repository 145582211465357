/**
 * @ngdoc Controller
 * @name LicensesCtrl
 * @description Licenses controller
 */
app.controller('LicensesCtrl', ["$scope", "$http", "LoaderFactory", "$window", "$timeout",
  function($scope, $http, LoaderFactory, $window, $timeout) {
  var init;
  var licenceFileIndex = 0;
  $scope.libraries = [];

  function * loadLicebseFile () {
    let licenceFileIndex = 1;
    let done = false;

    while (done === false) {
      LoaderFactory.show();

      yield Promise.resolve($http.get(`./licenses/licenses_${licenceFileIndex}.json`))
      .then((r) => r.data)
      .then((data) => {
        return Object.keys(data).map((key) => {
          const name = /[^@]+/.test(key) ? /[^@]+/.exec(key)[0] : '';
          const version = /[^@]+$/.test(key) ? /[^@]+$/.exec(key)[0] : '';

          if (name) {
            return {
              name,
              version,
              license: data[key].licenses,
              url: data[key].licenseUrl
            }
          } else return null;
        }).filter((item) => item);
      })
      .then((list) => {
        $scope.libraries = $scope.libraries.concat(list);
      })
      .catch((err) => {
        console.log(err);
        done = true;
      })
      .then(() => LoaderFactory.hide());

      licenceFileIndex++;
    }
  }

  init = function() {
    $scope.openLicense = function (url) {
      return $window.open(url, '_blank');
    };

    let iterator = loadLicebseFile();
    let res = iterator.next();

    $scope.scrollToEnd = function () {
      if (res.done === false) {
        res.value.then(() => {
          res = iterator.next();
        })
      }
    };
  };

  return init();
}]);
