app.controller('RestorePasswordCtrl', function ($scope, $location, $translate, AppFactory, Notification, AuthService, LoaderFactory, ErrFactory, API) {
  $scope.email = '';
  $scope.userForm = null;
  $scope.loading = false;

  const UPDATE_PASSWORD_BY_TOKEN = 'go_to_update_password_by_token';

  $scope.restoreEmail = () => {
    if (!$scope.email || $scope.email.trim() === 0) {
      return $translate(['error', 'enterEmailOrPNumber']).then((result) => {
        return Notification.alert({
          title: 'error',
          desc: 'enterEmailOrPNumber'
        });
      });
    }

    let rgx = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$|^\d+$/;
    if (!rgx.test($scope.email.trim())) {
      return $translate(['error', 'activationAccount.enterEmailOrPNumber']).then((result) => {
        return Notification.alert({
          title: 'error',
          desc: 'activationAccount.emailPersonnelNumberInvalid'
        });
      });
    }

    if (!AppFactory.isNetwork()) {
      return AppFactory.networkProblem();
    }

    $scope.loading = true;
    LoaderFactory.show();

    AuthService.restorePassword($scope.email.trim())
      .then((ans) => {
        LoaderFactory.hide();
        if (ans.action === UPDATE_PASSWORD_BY_TOKEN) {
          $translate(['successfully', 'successRestorTokenSent']).then((t) => {
            Notification.alert({
              title: 'notice',
              desc: 'successRestorTokenSent'
            }, () => {
              $location.path('/restore-password-by-token');
              $scope.loading = false;
              $scope.$apply();
            });
          });
        } else handlerSuccess();
      }).catch((err) => {
        LoaderFactory.hide();
        if (err instanceof ErrFactory) err.notify(() => $scope.loading = false);
        else {
          console.error(err);
          $scope.loading = false;
        }
      });
  };

  $scope.restorePasswordWithToken = () => {
    if ($scope.password !== $scope.password_confirmation) {
      return $translate(['error', 'errors.samePasswords']).then((result) => {
        return Notification.alert({
          title: 'error',
          desc: 'errors.samePasswords'
        });
      });
    }

    if (!AppFactory.isNetwork()) {
      return AppFactory.networkProblem();
    }

    $scope.loading = true;
    LoaderFactory.show();

    Promise.resolve(API.Users.restorePasswordByToken({
      user: {
        reset_password_token: $scope.token.trim(),
        password: $scope.password.trim(),
        password_confirmation: $scope.password_confirmation.trim()
      }
    }).$promise)
      .then(() => {
        LoaderFactory.hide();
        return Notification.alert({
          title: 'successfully',
          desc: 'successRestorePasswordByToken'
        }, () => {
          $location.url($location.path('/sign-in'));
          $scope.$apply();
        });
      })
      .catch((err) => {
        if (err instanceof ErrFactory) err.notify(() => $scope.loading = false);
        else {
          console.error(err);
          $scope.loading = false;
        }
      })
      .then(() => {
        LoaderFactory.hide();
      });
  };

  function handlerSuccess () {
    Notification.alert({
      title: 'successfully',
      desc: 'successRestorePassword'
    }, () => {
      $location.url($location.path('/sign-in?email=' + $scope.email));
      $scope.$apply();
    });
  }
});
