/**
 * @ngdoc Controller
 * @name DebugScreenCtrl
 * @description Debug Screen controller
 */
window.app.controller('DebugScreenCtrl', ['$scope', 'API', 'AuthService', 'Config', 'LocalizationService', 'Notification', 'appVersion', 'appName',
                                  function($scope,   API,   AuthService,   Config,   LocalizationService,   Notification,   appVersion,   appName) {
      var consoleErrors, apiCalls;

    $scope.dateFormat = window.localStorage.getItem('date_format');

    consoleErrors = window.localStorage.getItem('consoleErrors');
    apiCalls = window.localStorage.getItem('apiCalls');

    $scope.isLanguageSwitcher= true;
    $scope.logConfig = {};

    $scope.exportErrorLogs = function() {
      $scope.logConfig.errorsSending = true;
      this.sendLogs(consoleErrors)
        .then(() => {
          $scope.removeErrorLogs();
          Notification.alert({title: 'Logs sending', desc: 'Logs successfully sent'});
        })
        .catch(() => Notification.alert({title: 'Errors logs sending', desc: 'something went wrong'}))
        .finally(() => {
          $scope.logConfig.errorsSending = false;
          $scope.$apply();
        });
    }

    $scope.exportApiCalls = function() {
      $scope.logConfig.apiCallsSending = true;
      this.sendLogs(apiCalls)
        .then(() => {
          $scope.removeApiCallsLogs();
          Notification.alert({title: 'API calls logs sending', desc: 'API Calls logs successfully sent'});
        })
        .catch(() => Notification.alert({title: 'API calls logs sending', desc: 'something went wrong'}))
        .finally(() => {
          $scope.logConfig.apiCallsSending = false;
          $scope.$apply();
        });
    }

    $scope.sendLogs = function(logs) {
      return Promise.all(logs.map((log) => {
        log = Object.assign({
          app_env: Config.hostName,
          app_ver: appVersion,
          app_name: appName,
        }, log);
        return API.sendToElasticSearch(log);
      }));
    }

    $scope.removeErrorLogs = function() {
      window.localStorage.removeItem('consoleErrors');
      $scope.consoleErrors = [];
    }

    $scope.removeApiCallsLogs = function() {
      window.localStorage.removeItem('apiCalls');
      $scope.apiCalls = [];
    }

    $scope.curentHostName = Config.hostName;
    $scope.hostsList = Object.keys(app.AM.hosts);

    $scope.onSwitchApi = function (hostName) {
      Config.hostName = hostName;
      AuthService.logout();
    }

    if (AuthService.authorizedUser) {
      $scope.isLanguageSwitcher = AuthService.authorizedUser.is_beta_mode;
    }

    //stores Language set in the switcher in the localstorage
    $scope.currentLang = localStorage.getItem('selectedLang') || LocalizationService.defaultLang;
    $scope.langList = LocalizationService.supportLangs;

    $scope.onSwitchLang = function (deviceLanguage) {
      $scope.currentLang = deviceLanguage;
      localStorage.setItem('selectedLang', deviceLanguage);
      LocalizationService.localization(deviceLanguage);
    }

    if (consoleErrors) {
      consoleErrors = JSON.parse(consoleErrors);
      $scope.consoleErrors = consoleErrors.reverse();
    } else {
      $scope.consoleErrors = [];
    }

    if (apiCalls) {
      apiCalls = JSON.parse(apiCalls);
      $scope.apiCalls = apiCalls.reverse();
    } else {
      $scope.apiCalls = [];
    }
}]);
