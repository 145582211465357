
/**
 * @ngdoc Controller
 * @name InfoCtrl
 * @description Info controller
 */
window.app.controller('InfoCtrl', ["$scope", "$window", "appVersion", "appBuildDate",
                           function($scope,   $window,   appVersion,   appBuildDate) {

  $scope.version_build = '';

  /**
   * Construct
   * @memberof InfoCtrl
   * @private
   */
  function init () {
    if ($window.device) $scope.device = device.platform.toLowerCase();
    else $scope.device = 'browser';

    $scope.version_build = appVersion + ' ' + appBuildDate;
  };

  /**
   * Parse xml data
   * @memberof InfoCtrl
   * @private
   */
  parseXml = function(xml) {
    var dom, e;
    dom = null;
    if (window.DOMParser) {
      try {
        dom = (new DOMParser).parseFromString(xml, 'text/xml');
      } catch (error1) {
        e = error1;
        dom = null;
      }
    }
    return dom;
  };
  return init();
}]);
